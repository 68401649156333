import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Showcase/layout';
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const VisualDesign = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="Visual Design" />
    <section className="intro-section">
      <span className="tag-line">Brand Standards | Design Exploration | Prototyping</span>
      <h1>Visual Design</h1>
      <p>As the wireframes and prototypes are being developed, we consider how a visual design system can <strong>enhance the look and feel of the new site.</strong> We review the creative aspirations from the discovery phase, existing brand direction, and relevant findings from the content audit to <strong>create a cohesive visual system</strong> and guidelines for how to use it across the site.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/visual-design/design-exploration.jpg"
        width={4292}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Design Exploration"
        />
      </Parallax>
      <figcaption>Design Exploration</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Design Exploration - Mood Boards - Image Direction - Content Collection Guidelines</p>

      <div className="portfolio-card horizontal-parallax">
              <Parallax translateX={[20,- 30]}>
                <figure>
                  
              <StaticImage
                src="../assets/images/visual-design/moodboards.jpg"
                width={2400}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Mood Boards"
                />
                </figure>
              </Parallax>
              <figcaption>Mood Boards</figcaption>
      </div>
      

      <div className="portfolio-card">

        <figure>
        <StaticImage
        src="../assets/images/visual-design/mobile-first-design.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Mobile First Design"
        />
        
        </figure>
        <figcaption>Mobile First Design</figcaption>
      </div>
      <div className="portfolio-card parallax">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/visual-design/desktop-design.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Desktop Design"
        />
        </figure>
        </Parallax>
        <figcaption>Desktop Design</figcaption>
      </div>

      <div className="portfolio-card horizontal-parallax">
              <Parallax translateX={[0,-40]}>
                <figure>
                  
              <StaticImage
                src="../assets/images/visual-design/moodboards2.jpg"
                width={2400}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Modular Design and Visual Components Research"
                />
                </figure>
              </Parallax>
              <figcaption>Modular Design and Visual Components Research</figcaption>
      </div>

    </section>

    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">
          <li className="content-card">
            <Link to="/content-strategy">
              <StaticImage
                src="../assets/images/on-page-content-strategy-copywriting-production-and-planning.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>On-page Content Strategy, Copywriting & Production Planning</h3>
                <p>Page-by-Page Content Strategy | Copywriting | Interactive Web Content | User Acceptance Testing</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="/seo-analytics-and-reporting">
              <StaticImage
                src="../assets/images/seo-analytics-reporting.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>SEO Analytics & Reporting</h3>
                <p>Data Dashboard | SEO Reporting | Optimization Recommendations</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>
    
  </Layout>
  </ParallaxProvider>
)

export default VisualDesign
